import useCurrentUser from "Organiser/useCurrentUser"
import ScriptEditor from "Organiser/components/ScriptEditor/ScriptEditor"
import ReminderTimes from "./Partials/ReminderTimes"
import { Controller } from "react-hook-form"
import { Link } from "react-router-dom"

// froala
import "froala-editor/js/froala_editor.pkgd.min.js"
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/css/froala_style.min.css"
import "froala-editor/css/froala_editor.pkgd.min.css"
import FroalaEditor from "react-froala-wysiwyg"
import FroalaEditorImg from "react-froala-wysiwyg/FroalaEditorImg"

export default function ActionBuild() {
  const act = useAct()
  const { checklistId, actionId } = useParams()
  const history = useHistory()
  const checklist = useEager("checklists", checklistId)
  const title = actionId ? "Editing step" : "Add a step"
  const action = useSelector((s) =>
    actionId ? s.actions.entities[actionId] : {}
  )
  const isLive = checklist.state === "live"
  const canRemove = _.includes(["draft", "live"], checklist.state) && actionId
  const [name, setName] = useState("")
  const [description, setDescription] = useState("Instructions")
  const [remindersEnabled, setRemindersEnabled] = useState(false)
  const [thankyouEmail, setThankyouEmail] = useState(false)
  const [liveTracking, setLiveTracking] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [dashboardId, setDashboardId] = useState(null)
  const [reminderSettings, setReminderSettings] = useState([])
  const [confirmArchive, setConfirmArchive] = useState(false)
  const [redirectActionId, setRedirectActionId] = useState(null)
  const canEnableLiveTracking = actionId !== null
  const canToggleReminders = useSelector(
    (s) =>
      _.filter(_.values(s.actions.entities), (otherAction) => {
        return otherAction.actionOrder < action?.actionOrder
      }).length > 0
  )
  const { dashboards } = useSelector((s) => s.local)

  useEffect(() => {
    act.dashboards.load()
  }, [])

  useEffect(() => {
    act.checklists.load(checklistId)
  }, [checklistId])

  useEffect(() => {
    if (action) {
      setName(action.name)
      setDescription(action.description)
      setLiveTracking(action.liveTracking)
      setDashboardId(action.dashboardId)
      setRemindersEnabled(action.remindersEnabled)
      setThankyouEmail(action.thankyouEmail)
      setIsEditable(action.isEditable)
      setReminderSettings(action.reminderSettings || [])
    }
  }, [JSON.stringify(action)])

  function save() {
    const payload = {
      name,
      description,
      remindersEnabled,
      thankyouEmail,
      reminderSettings,
      liveTracking,
      isEditable,
      dashboardId,
    }
    act.actionEditor
      .persist(checklistId, actionId || "new", payload)
      .then((response) => {
        if (actionId) {
          act.scriptEditor.persist(checklistId, response.data.id).then(() => {
            history.push(`/checklists/${checklistId}`)
          })
        } else {
          history.push(
            `/checklists/${checklistId}/actions/${response.data.id}/edit`
          )
        }
      })
  }

  function remove() {
    act.actionEditor
      .remove(checklistId, actionId, redirectActionId)
      .then(() => {
        history.push(`/checklists/${checklistId}`)
      })
  }

  return (
    <div>
      <div className="title">
        <h2>
          {checklist.name}
          <div className="ellipsis wider" />
          <span className="dark-grey">{title}</span>
        </h2>
        {canRemove && (
          <div className="actions">
            <button
              onClick={() => (isLive ? setConfirmArchive(true) : remove())}
              className="button red"
            >
              {isLive ? "Unpublish" : "Delete"}
            </button>
          </div>
        )}
      </div>

      {confirmArchive && (
        <div className="alert">
          <p>Are you sure you want to archive this action?</p>
          <label>
            You can optionally choose to redirect people already on this action
            to another:
          </label>
          <AsyncSelect
            entity="action"
            value={redirectActionId}
            queryParameters={{ checklistId: checklist.id, excludeId: actionId }}
            onChange={(v) => setRedirectActionId(v)}
          />
          <div className="buttons margin-top">
            <ServerWaitButton
              className="button smallest primary"
              loaderHeight={12}
              loaderColor={"black"}
              onClick={remove}
            >
              Yes
            </ServerWaitButton>
            <a
              className="button smallest primary"
              onClick={() => setConfirmArchive(false)}
            >
              No
            </a>
          </div>
        </div>
      )}

      <div className="box">
        <h3 className="no-margin-top">About</h3>

        <div className="group">
          <label>Name</label>
          <input
            type="text"
            className="wider action-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="group">
          <label>Instructions</label>
          <FroalaEditor
            tag="textarea"
            config={{
              key: ENV.FROALA_KEY,
              imageUploadToS3: window.ENV.S3_CONFIG,
            }}
            model={description}
            onModelChange={(text) => setDescription(text)}
          />
        </div>

        <div className="group">
          <label>
            Let members edit their answers to this action after completing it
          </label>
          <p>
            If enabled, members will see a link to this action on their
            dashboard after completing it, and will be able to update their
            answers.
          </p>

          <label>
            <input
              type="checkbox"
              className="wider"
              checked={isEditable}
              onChange={(e) => setIsEditable(e.target.checked)}
            />
          </label>
        </div>

        <div className="group">
          <label>Enable thankyou emails</label>
          <p>
            If enabled, members will receive a thankyou email when they complete
            this action.
          </p>

          <label>
            <input
              type="checkbox"
              className="wider"
              checked={thankyouEmail}
              onChange={(e) => setThankyouEmail(e.target.checked)}
            />
          </label>
        </div>

        {canEnableLiveTracking && (
          <div className="group">
            <label>Use a rep dashboard</label>
            <p>
              If enabled, reps will see a rep dashboard for this action.
            </p>
            <input
              type="checkbox"
              checked={liveTracking}
              onChange={(e) => setLiveTracking(e.target.checked)}
            />
          </div>
        )}

        {liveTracking && (
          <div className="group">
            <label>Rep dashboard</label>
            <p>
              Choose a dashboard to display live tracking data for this action.
            </p>
            <select
              value={dashboardId}
              onChange={(e) => setDashboardId(e.target.value)}
            >
              {(dashboards || []).map((dashboard) => (
                <option key={dashboard.id} value={dashboard.id}>
                  {dashboard.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {canToggleReminders && (
          <div className="group">
            <label>Enable automatic reminders</label>
            <p>
              If enabled, people will be reminded to complete this step 1, 3,
              and 7 days after reaching it.
            </p>

            <label>
              <input
                type="checkbox"
                className="wider reminder-frequency"
                checked={remindersEnabled}
                onChange={(e) => setRemindersEnabled(e.target.checked)}
              />
              Enable
            </label>

            {remindersEnabled && (
              <div>
                <p>By default, reminders are sent every 1, 3, and 7 days.</p>
                <ReminderTimes
                  times={reminderSettings}
                  callback={(v) => setReminderSettings(v)}
                />
              </div>
            )}
          </div>
        )}

        {actionId && (
          <div>
            <p className="bold">
              You can deeplink people to this action by adding the following to
              the end of their magic links:
            </p>
            <p className="code">&action_id={actionId}</p>
            {liveTracking && <><p className="bold">
              You can deeplink reps to the rep dashboard for this action by adding the following to
              the end of their magic links:
            </p>
              <p className="code">&tracking_id={actionId}</p></>}
          </div>
        )}
      </div>

      {actionId && (
        <div className="margin-top double box">
          <h3 className="no-margin-top">Questions</h3>
          <ScriptEditor
            checklistId={Number(checklistId)}
            actionId={Number(actionId)}
          />
        </div>
      )}

      <div className="buttons margin-top double">
        <button className="button primary" onClick={save}>
          Save
        </button>
        <Link to={`/checklists/${checklistId}`} className="button link naked">
          Cancel
        </Link>
      </div>

      <div className="margin-top double box">
        👋 You can test this survey out after you save, before it is sent to any
        users
      </div>
    </div>
  )
}

import './Question.scoped.scss'
import Answer from './Answer'
import { useDrag } from 'react-dnd'
import { useDrop } from 'react-dnd'
import { useStore } from 'react-redux'
import detectCycle from './detectCycle.js'
import { Popover } from '@varld/popover'
import MoreSvg from "../../../../images/more.svg"
import DeleteSvg from "../../../../images/delete.svg"
import FroalaEditor from 'react-froala-wysiwyg'
import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg'
import AdvancedQuestionSettings from './AdvancedQuestionSettings'

const labelMappings = {
  'intro': 'Introduction',
  'instruction': 'Instruction',
  'multiple_choice': 'Question',
  'multi_select': 'Question',
  'rating': 'Question',
  'text': 'Text question',
  'number': 'Number question',
  'date': 'Pick a date',
  'file': 'Upload a file',
  'download': 'Download a file'
}

export default function Question({ actionId, id, softLoad = false }) {
  const act = useAct()
  const store = useStore()
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [loaded, setLoaded] = useState(!softLoad)

  const { question, inputType, questionOrder, instructions, fileUrl, hideAnswers, hidden, settings } = useSelector(s => s.questions.entities[id])
  const answers = useSelector(s => _.orderBy(_.values(s.answers.entities).filter(a => a.questionId === id), 'answerOrder'))
  const hasAdvancedLogic = !_.every(answers, a => a.action === 'next_question')
  const answerIds = answers.map(a => a.id)
  const label = labelMappings[inputType]
  const hasAnswers = _.includes(['multiple_choice', 'multi_select', 'rating'], inputType)

  const [, drag, preview] = useDrag({
    item: { type: 'question', id }
  })

  const [, drop] = useDrop({
    accept: 'question',
    drop(item, monitor) {
      const state = store.getState()
      const questions = _.values(state.questions.entities)
      const answers = _.values(state.answers.entities)

      let affectedIds = _.orderBy(questions.filter(e => e.id !== id), 'questionOrder').map(e => e.id)
      affectedIds.splice(questionOrder, 0, item.id)

      // Apply update to copy of questions to check for cycles
      const localQuestions = questions.map((q, i) => {
        const newQuestionOrder = affectedIds.indexOf(q.id)
        return { ...q, questionOrder: newQuestionOrder }
      })

      if (detectCycle(localQuestions, answers)) {
        act.local.upsert({ error: "Sorry, moving this question here would create a loop in your script. Check your answer rules and try again." })
      } else {
        act.scriptEditor.questions.moveOrder(item.id, questionOrder)
      }
    }
  })

  useEffect(() => {
    if (hasAdvancedLogic) setShowAdvanced(true)
  }, [answers])

  const ref = el => drag(drop(el))

  return loaded ? <div className={`question question-${inputType}`} ref={ref}>
    <div className='attributes'>
      <label>{label}</label>
      <textarea value={question} onChange={(e) => act.scriptEditor.questions.upsert(id, { question: e.target.value })} />
    </div>

    {inputType === 'text' && <div className='indent'><p>This is a text question. Members can enter any answer.</p></div>}
    {inputType === 'number' && <div className='indent'><p>This is a number question. Reps can enter a number.</p></div>}
    {inputType === 'date' && <div className='indent'><p>This is a date question. Members pick a date as their answer.</p></div>}
    {inputType === 'file' && <div className='indent'><p>This is a file upload question. Members upload an image or document as their answer.</p></div>}
    {inputType === 'download' && <div className='indent'><p>This is a file download question.</p></div>}
    {inputType === 'instruction' && <div className='indent'><p>This is an instruction question. There are no answers.</p></div>}
    {inputType === 'address' && <div className='indent'><p>This is an address question. Members enter an address as their answer.</p></div>}

    {
      hasAnswers &&
      <div className='answers'>
        {answerIds.map(id => <Answer id={id} key={id} showAdvanced={showAdvanced} />)}
        <a className='button naked add-answer' onClick={() => act.scriptEditor.answers.add({ questionId: id, answerOrder: answers.length })}>Add an answer</a>
      </div>
    }

    {!hasAnswers && <div className='margin-left double advanced'>
      <AdvancedQuestionSettings actionId={actionId} questionId={id} />
    </div>}

    <div className="margin-left double">
      {Number.isInteger(id) && <span className='small smaller bold dark-grey'>ID: {id}</span>}

      <br />
      <br />
      <label>
        <input
          type='checkbox'
          className='wider'
          checked={hidden}
          onChange={(e) => act.scriptEditor.questions.upsert(id, { hidden: e.target.checked })}
        />
        Hide this question
      </label>
      <p class="small smaller">Please note if another question jumps to this one, it will still be visible.</p>

      {
        hasAnswers && <label>
          <input
            type='checkbox'
            className='wider'
            checked={hideAnswers}
            onChange={(e) => act.scriptEditor.questions.upsert(id, { hideAnswers: e.target.checked })}
          />
          Hide what members answer from exports
        </label>
      }

      {
        inputType === 'download' && <div className='group margin-left'>
          <label>URL of file</label>
          <input type='text' className='wider' value={fileUrl} onChange={(e) => act.scriptEditor.questions.upsert(id, { fileUrl: e.target.value })} />
        </div>
      }

      <div className='divider'></div>

      {!showInstructions && instructions && <p className='margin-left'>This question has instructions set up. You can edit them by clicking the three dots below.</p>}
      {
        showInstructions &&
        <div className='instructions'>
          <label className='bold margin-bottom half'>Instructions</label>
          <FroalaEditor
            tag='textarea'
            config={{
              key: ENV.FROALA_KEY,
              imageUploadToS3: window.ENV.S3_CONFIG
            }}
            model={instructions}
            onModelChange={text => act.scriptEditor.questions.upsert(id, { instructions: text })}
          />
        </div>
      }
    </div>

    <div className='actions'>
      <Popover popover={({ close }) => {
        return <div className='popup-list popup'>
          <div className='group'>
            {!showInstructions && <div className='filter' onClick={() => { setShowInstructions(!showInstructions); close() }}>
              Add instructions
            </div>}
            {hasAnswers && <div className='filter' onClick={() => { setShowAdvanced(!showAdvanced); close() }}>
              {showAdvanced ? 'Hide' : 'Show'} advanced settings
            </div>}
          </div>
        </div>
      }}>
        <a className='more'><img src={MoreSvg} /></a>
      </Popover>
      <div className='divider vertical'></div>
      <a className='delete' onClick={() => act.scriptEditor.questions.remove(id)}><img src={DeleteSvg} /></a>
    </div>
  </div > : <div className={`question question-${inputType}`} ref={ref}>
    <div className='attributes'>
      <label>{label}</label>
      <h5>{question}</h5>
      <br /><br />
      <a className='button smallest small smaller' onClick={() => setLoaded(true)}>Load</a>
    </div>
  </div>
}

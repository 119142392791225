import urlify from '../../urlify'
import RatingQuestion from './interfaces/RatingQuestion'
import './Question.scoped.scss'

export default function Question({ question, action, checklistId }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const act = useAct()
  const answers = useSelector(s => _.orderBy(_.filter(_.values(s.answers.entities), { questionId: parseInt(question.id) }), 'answerOrder'))
  const hasInstructions = !(!question.instructions || question.instructions.length === 0)
  const responseAnswers = useSelector(s => s.responseAnswers)
  const responseAnswer = useSelector(state =>
    _.find(state.responseAnswers.entities, { questionId: question.id }) || null
  );

  console.log('ra', responseAnswers, responseAnswer)

  useEffect(() => {
    if (responseAnswer) {
      Object.keys(responseAnswer).forEach((key) => {
        if (['answerId', 'answerText', 'answerDate'].includes(key) && responseAnswer[key]) {
          setValue(key, responseAnswer[key].toString());
          console.log('set', key, responseAnswer[key], typeof responseAnswer[key])
        } else if (key === 'answerIds' && responseAnswer[key]) {
          setValue('answers', responseAnswer[key].map((a) => a.toString()));
        }
      });
    }
  }, [responseAnswer, setValue]); // Only re-run if previousAnswer changes

  useEffect(() => {
    if (question.inputType === 'address') {
      window.c2a.attach({
        search: `search-bar`,
        postcode: `address-zip`,
      },
        {
          onResultSelected: function (c2a, elements, address) {
            // Perform any action here with the available data.
            // For example, you could reveal all the form fields when the fields are filled.
            console.log(address)
            var parts = [address.company_name, address.line_1, address.line_2, address.locality, address.province_name]
            parts = parts.filter((a) => a)
            setValue('address1', parts.length > 0 ? parts[0] : '')
            setValue('address2', parts.length > 1 ? parts[1] : '')
            setValue('address3', parts.length > 2 ? parts[2] : '')
            setValue('address4', parts.length > 3 ? parts[3] : '')
            setValue('address5', parts.length > 4 ? parts[4] : '')

            setValue('postcode', address.postal_code)
          }
        })
    }
  }, [])

  const handleSubmitMultipleChoiceAnswer = ({ answerId }) => {
    act.action.answerMultipleChoiceQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answerId,
    })
  }

  const handleSubmitMultiSelectAnswer = ({ answers }) => {
    act.action.answerMultiSelectQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answers,
    })
  }

  const handleSubmitRatingAnswer = (answers) => {
    act.action.answerRatingQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answers,
    })
  }

  const handleSubmitTextAnswer = ({ answerText }) => {
    act.action.answerTextQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answerText,
    })
  }

  const handleSubmitDownloadAnswer = ({ answerText }) => {
    act.action.answerTextQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answerText: answerText,
    })
  }

  const handleSubmitDateAnswer = ({ answerDate }) => {
    act.action.answerDateQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answerDate,
    })
  }

  const handleSubmitFileAnswer = ({ answerFile }) => {
    act.action.answerFileQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      answerFile,
    })
  }

  const handleSubmitAddressAnswer = ({ address1, address2, address3, address4, address5, postcode }) => {
    act.action.answerAddressQuestion({
      checklistId,
      actionId: action.id,
      questionId: question.id,
      address: {
        address1, address2, address3, address4, address5, postcode
      }
    })
  }

  function download() {
    setValue('answerText', 'yes')
  }

  return (
    <>
      <h2>
        <span className='dark-grey'>{action.name}</span>
        <br /><span dangerouslySetInnerHTML={{ __html: urlify(question.question) }}></span>
      </h2>

      <div className='question'>
        <div className="form box">
          {question.inputType === "multiple_choice" && (
            <form onSubmit={handleSubmit(handleSubmitMultipleChoiceAnswer)}>
              {answers.map(answer => (
                <div key={answer.id}>
                  <label>
                    <input
                      type="radio"
                      value={answer.id}
                      {...register("answerId", { required: true })}
                    />
                    {answer.answer}
                  </label>
                </div>
              ))}
              {errors.answerId && <div>You must choose an answer</div>}

              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "multi_select" && (
            <form onSubmit={handleSubmit(handleSubmitMultiSelectAnswer)}>
              {answers.map(answer => (
                <div key={answer.id}>
                  <label>
                    <input
                      type="checkbox"
                      name="answers"
                      value={answer.id}
                      {...register("answers", { required: true })}
                    />
                    {answer.answer}
                  </label>
                </div>
              ))}
              {errors.answerId && <div>You must choose an answer</div>}

              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === 'rating' && (
            <RatingQuestion answers={answers} onSubmit={(answers) => { console.log(answers); handleSubmitRatingAnswer(answers) }} />
          )}
          {question.inputType === "instruction" && (
            <form onSubmit={handleSubmit(handleSubmitMultiSelectAnswer)}>
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "text" && (
            <form onSubmit={handleSubmit(handleSubmitTextAnswer)}>
              <div>
                <label>
                  <textarea
                    className='wide'
                    {...register("answerText", { required: true })}
                  ></textarea>
                </label>
              </div>
              {errors.answerText && <div>You must enter an answer</div>}
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "number" && (
            <form onSubmit={handleSubmit(handleSubmitTextAnswer)}>
              <div>
                <label>
                  <input
                    type="number"
                    {...register("answerText", { required: true })}
                  ></input>
                </label>
              </div>
              {errors.answerText && <div>You must enter an answer</div>}
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "date" && (
            <form onSubmit={handleSubmit(handleSubmitDateAnswer)}>
              <div>
                <label>
                  <input
                    type="date"
                    {...register("answerDate", { required: true })}
                  ></input>
                </label>
              </div>
              {errors.answerDate && <div>You must enter a date</div>}
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "download" && (
            <form onSubmit={handleSubmit(handleSubmitDownloadAnswer)}>
              <div>
                <label>
                  <a className='button secondary' href={question.fileUrl} target={'_blank'} onClick={download}>Download file</a>
                  <input
                    type="hidden"
                    {...register("answerText")}
                  ></input>
                </label>
              </div>
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "file" && (
            <form onSubmit={handleSubmit(handleSubmitFileAnswer)}>
              <div>
                <label>
                  <input
                    type="file"
                    {...register("answerFile", { required: true })}
                  ></input>
                </label>
              </div>
              {errors.answerFile && <div>You must upload a file</div>}
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
          {question.inputType === "address" && (
            <form className='address' onSubmit={handleSubmit(handleSubmitAddressAnswer)}>
              <div className='group'>
                <label>Start typing to find your address</label>
                <input type="text" className='wider' id="search-bar" />
              </div>
              <div className='group'>
                <label className='mb'>Your address</label>
                <input
                  type="text"
                  id="address-line-1"
                  placeholder='Address line 1'
                  className='wider'
                  {...register("address1", { required: true })}
                ></input>
                {errors.address1 && <div>You must enter your first address line</div>}
              </div>
              <div className='group'>
                <input
                  type="text"
                  id="address-line-2"
                  placeholder='Address line 2'
                  className='wider'
                  {...register("address2", { required: false })}
                ></input>
                {errors.address2 && <div>You must enter your second address line</div>}
              </div>
              <div className='group'>
                <input
                  type="text"
                  id="address-town"
                  placeholder='Address line 3'
                  className='wider'
                  {...register("address3", { required: false })}
                ></input>
              </div>
              <div className='group'>
                <input
                  type="text"
                  id="address-county"
                  placeholder='Address line 4'
                  className='wider'
                  {...register("address4", { required: false })}
                ></input>
              </div>
              <div className='group'>
                <input
                  type="text"
                  id="address-country"
                  placeholder='Address line 5'
                  className='wider'
                  {...register("address5", { required: false })}
                ></input>
              </div>
              <div className='group'>
                <i>Postcode</i><br />
                <input
                  type="text"
                  id="address-zip"
                  placeholder='Postcode'
                  className='wider'
                  {...register("postcode", { required: true })}
                ></input>
                {errors.postcode && <div>You must enter a postcode</div>}
              </div>
              <button className='button primary margin-top'>Next</button>
            </form>
          )}
        </div>

        {hasInstructions &&
          <div className="instructions box">
            <h3 className='no-margin-top'>Instructions</h3>
            <p dangerouslySetInnerHTML={{ __html: urlify(question.instructions) }}></p>
          </div>
        }
      </div>
    </>
  );
}

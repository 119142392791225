import Select from 'react-select'
import './AdvancedSettings.scoped.scss'

function buildDescription(nextQuestionId) {
    if (!nextQuestionId) {
        return null
    } else {
        return 'This answer moves reps to another question.'
    }
}

function buildOptionTree(questions) {
    var tree = [
        {
            label: "Basic",
            disabled: true,
            options: [
                { value: { nextQuestionId: null }, label: 'Next question' }
            ]
        },
        {
            label: "Move to another question",
            disabled: true,
            options: questions
        }
    ]
    return tree
}

function deepSearch(options, value, tempObj = {}) {
    if (options && value != null) {
        options.find((node) => {
            console.log('on node', node.value, value, node.value === value)
            if (_.isEqual(node.value, value)) {
                tempObj.found = node
                return node
            }
            return deepSearch(node.options, value, tempObj)
        })
        if (tempObj.found) {
            return tempObj.found
        }
    }
    return undefined
}

export default function AdvancedQuestionSettings({ actionId, questionId }) {
    const act = useAct()
    const questions = useSelector(s => _.values(s.questions.entities).filter(question => question.actionId === actionId))
    const { nextQuestionId } = useSelector(s => s.questions.entities[questionId])
    const [expanded, setExpanded] = useState(false)
    const description = buildDescription(nextQuestionId)
    const hasDescription = description !== null

    // build our option array of what we can do next
    const moveableQuestions = questions.map(q => {
        return { value: { nextQuestionId: q.id }, label: q.question, disabled: false }
    })
    const options = buildOptionTree(moveableQuestions)

    return <div className='advanced'>
        {!expanded &&
            <p className='smaller'>
                {hasDescription && <span>{description}</span>}
                <a className={`button naked link blue ${hasDescription ? 'margin-left' : ''}`} onClick={() => setExpanded(true)}>{hasDescription ? 'Change' : 'Add effect'}</a>
            </p>
        }
        {expanded &&
            <>
                <p>After this:</p>
                <div className='select'>
                    <Select
                        defaultValue={deepSearch(options, { nextQuestionId })}
                        options={options}
                        isOptionDisabled={o => o.disabled}
                        onChange={option => act.scriptEditor.questions.upsert(questionId, option.value)}
                    />
                </div>
            </>
        }
    </div>
}
function actions(dis, store, restClient) {
  function checkFakeResonseMode() {
    return store.getState().local.fakeResponseMode
  }

  function load(id, actionId) {
    if (checkFakeResonseMode()) {
      const questions = _.filter(_.values(store.getState().questions.entities), { actionId: parseInt(actionId) })
      const firstQuestionId = _.orderBy(questions, 'questionOrder')[0]?.id
      dis({ type: "actionResponses/upsert", payload: { id: -1, currentQuestionId: firstQuestionId } })

      return Promise.resolve()
    }

    return restClient.get(`/api/action_responses/${id}`)
      .then(({ data }) => {
        const { actionResponse, responseAnswers, user } = data

        dis({ type: "actionResponses/upsert", payload: actionResponse })
        dis({ type: "users/upsert", payload: user })
        responseAnswers.forEach(ra => dis({ type: "responseAnswers/upsert", payload: ra }))
      })
  }

  function toggleConversation(actionId, userId) {
    return restClient.post(`/api/action_responses/toggle_conversation`, { actionId, userId })
  }

  function toggleDashboardColumn(actionId, userId, field) {
    return restClient.post(`/api/action_responses/toggle_dashboard_column`, { actionId, userId, field })
  }

  function fakeNextQuestion(answerId = null, actionId) {
    const state = store.getState()
    const currentQuestionId = state.actionResponses.entities[-1]?.currentQuestionId
    const currentQuestion = state.questions.entities[currentQuestionId]
    const currentAnswer = state.answers.entities[answerId] || {}

    if (currentAnswer.action === 'go_to_question') {
      const nextId = currentAnswer.nextQuestionId
      var nextQuestion = state.questions.entities[nextId]
    } else if (currentQuestion.nextQuestionId) {
      const nextId = currentQuestion.nextQuestionId
      var nextQuestion = state.questions.entities[nextId]
    } else {
      const currentOrder = currentQuestion.questionOrder
      const nextOrder = currentOrder + 1
      const questions = _.filter(_.values(store.getState().questions.entities), { actionId: parseInt(actionId) })
      var nextQuestion = _.find(questions, q => q.questionOrder === nextOrder)
    }

    if (nextQuestion) {
      dis({ type: "actionResponses/upsert", payload: { id: -1, currentQuestionId: nextQuestion.id } })
    } else {
      dis({ type: "actionResponses/upsert", payload: { id: -1, currentQuestionId: null, completedAt: true } })
    }
  }

  return { load, fakeNextQuestion, toggleConversation, toggleDashboardColumn };
}

export { actions }
import Loader from "react-loader-spinner";

export default function Loading({ children, width = 100, loaded }) {
  return (
    <>
      { !loaded &&
        <Loader
          type="Bars"
          color="#00BFFF"
          height={width}
          width={width}
          timeout={3000} // 3 secs
        />
      }
      { loaded && <>
        {children}
      </> }
    </>
  );
};

import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

// Froala
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import FroalaEditor from 'react-froala-wysiwyg'

function ColumnConfig({ column, dashboard, index }) {
  const act = useAct()
  const [source, setSource] = useState(column.source || "none");

  const handleChange = (e) => {
    setSource(e.target.value);

    let updatedColumn;

    switch (e.target.value) {
      case "none":
        updatedColumn = { ...column, source: "none" };
        break;
      case "audience":
        updatedColumn = { ...column, source: "audience" };
        break;
      case "custom_field":
        updatedColumn = { ...column, source: "custom_field" };
        break;
      case "from_question":
        updatedColumn = { ...column, source: "from_question" };
        break;
      default:
        break;
    }

    act.local.upsert({
      dashboard: {
        ...dashboard,
        schema: dashboard.schema.map((c, i) => i === index ? updatedColumn : c)
      }
    });
  };

  const handleIdChange = (e) => {
    act.local.upsert({
      dashboard: {
        ...dashboard,
        schema: dashboard.schema.map((c, i) => i === index ? { ...c, id: e.target.value } : c)
      }
    });
  };

  const handleCustomFieldChange = (e) => {
    act.local.upsert({
      dashboard: {
        ...dashboard,
        schema: dashboard.schema.map((c, i) => i === index ? { ...c, id: e.target.value, fromCustomField: e.target.value } : c)
      }
    });
  };

  const handleQuestionIdChange = (e) => {
    act.local.upsert({
      dashboard: {
        ...dashboard,
        schema: dashboard.schema.map((c, i) => i === index ? { ...c, id: e.target.value, fromQuestionId: e.target.value } : c)
      }
    });
  };

  return (
    <div className='group'>
      <label>Choose a data source</label>
      <p className='small smaller dark-grey'>If you select none, this column will be entirely populated by reps using the dashboard.</p>
      <div>
        <label>
          <input
            type="radio"
            value="none"
            checked={source === "none"}
            onChange={handleChange}
          />
          No data source
        </label>

        <label>
          <input
            type="radio"
            value="audience"
            checked={source === "audience"}
            onChange={handleChange}
          />
          Pull from Movement audience
        </label>

        {source === "audience" && <>
          <p className='small smaller dark-grey'>This is the ID for the audience within Movement that will be used to populate this column. This data is refreshed at 8:30am, and 8:30pm, so please allow time before this is filled on new dashboards.</p>
          <input
            type='text'
            value={column.id || ""}
            onChange={handleIdChange}
          />
        </> }

        <label>
          <input
            type="radio"
            value="custom_field"
            checked={source === "custom_field"}
            onChange={handleChange}
          />
          Pull from custom field
        </label>

        {source === "custom_field" && <>
          <p className='small smaller dark-grey'>This is the slug for the custom field to be used. This data is refreshed every morning.</p>
          <input
            type='text'
            value={column.fromCustomField || ""}
            onChange={handleCustomFieldChange}
          />
        </> }

        <label>
          <input
            type="radio"
            value="from_question"
            checked={source === "from_question"}
            onChange={handleChange}
          />
          Pull from an answer given within Activate
        </label>

        {source === "from_question" && <>
          <p className='small smaller dark-grey'>This is the ID for the question to be used. This data is pulled instantly, and is always up to date.</p>
          <p className='small smaller dark-grey bold'>You can find the ID by editing an action, in the script editor, or on the results page of an action.</p>
          <input
            type='text'
            value={column.fromQuestionId || ""}
            onChange={handleQuestionIdChange}
          />
        </> }
      </div>
    </div>
  );
}

export default function RepDashboardBuild() {
  const act = useAct()
  const history = useHistory()
  const { dashboard } = useSelector(s => s.local)
  const { dashboardId } = useParams()

  useEffect(() => {
    if (dashboardId !== 'new') {
      act.dashboards.loadDashboard(dashboardId)
    } else {
      act.local.upsert({
        dashboard: {
          name: '',
          helpText: '',
          schema: [],
          showStatus: true,
          enableConversations: true,
          conversationCustomField: '',
          statusLabel: 'Voted'
        }
      })
    }
  }, [])

  function add() {
    act.local.upsert({ dashboard: {
      ...dashboard,
      schema: [...dashboard.schema, { name: '', allowToggle: false, slug: '', id: '', colourCode: true }]
    } })
  }

  function save() {
    const payload = {
      schema: dashboard.schema,
      name: dashboard.name,
      helpText: dashboard.helpText,
      enableConversations: dashboard.enableConversations,
      conversationCustomField: dashboard.conversationCustomField,
      showStatus: dashboard.showStatus,
      statusLabel: dashboard.statusLabel
    }
    if (dashboardId === 'new') {
      act.dashboards.create(payload).then(() => {
        history.push(`/rep_dashboards`)
      })
    } else {
      act.dashboards.update(dashboardId, payload).then(() => {
        history.push(`/rep_dashboards`)
      })
    }
  }

  return (
    <div>
      <div className='title'>
        <h2>
          {dashboard?.name || 'Creating a new'}
          <div className='ellipsis wider' />
          <span className='dark-grey'>rep dashboard</span>
        </h2>
      </div>

      <div className="box">
        <h3 className='no-margin-top'>About</h3>

        <div className='group wider'>
          <label>Name</label>
          <input type='text' value={dashboard?.name} onChange={e => act.local.upsert({ dashboard: { ...dashboard, name: e.target.value } })} />
        </div>

        <div className='group wider'>
          <label>Help text</label>
          <FroalaEditor
            tag='textarea'
            config={{
              key: ENV.FROALA_KEY,
              toolbarButtons: ['bold', 'italic', 'underline', 'formatOL', 'formatUL']
            }}
            model={dashboard?.helpText}
            onModelChange={text =>
              act.local.upsert({ dashboard: { ...dashboard, helpText: text } })
            }
          />
        </div>

        <div className='group'>
          <label>Let reps toggle whether they have had a conversation with members</label>
          <input type='checkbox' checked={dashboard?.enableConversations} onChange={e => act.local.upsert({ dashboard: { ...dashboard, enableConversations: e.target.checked } })} />
        </div>

        { dashboard?.enableConversations &&
          <div className='group'>
            <label>Custom field slug</label>
            <p className='small bold smaller dark-grey'>If a custom field slug is entered here, 'y' or 'n' will be pushed back to NEU Communicator when this is toggled</p>
            <p className='small smaller dark-grey'>You can find this in your list of custom fields within NEU Communicator</p>
            <input type='text' value={dashboard?.conversationCustomField} onChange={e => act.local.upsert({ dashboard: { ...dashboard, conversationCustomField: e.target.value } })} />
          </div>
        }

        <div className='group'>
          <label>Show reps whether members have taken the action</label>
          <input type='checkbox' checked={dashboard?.showStatus} onChange={e => act.local.upsert({ dashboard: { ...dashboard, showStatus: e.target.checked } })} />
        </div>

        { dashboard?.showStatus && <div className='group'>
          <label>Action label (e.g. 'Voted')</label>
          <input type='text' value={dashboard?.statusLabel} onChange={e => act.local.upsert({ dashboard: { ...dashboard, statusLabel: e.target.value } })} />
        </div> }
      </div>

      <h3>Columns</h3>

      { dashboard?.schema.length === 0 && <p>No columns.</p> }
      { dashboard?.schema.map((column, index) => <div className='box margin-bottom'>
        <div className='group'>
          <label>Column name</label>
          <input type='text' value={column.name} onChange={e => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.map((c, i) => i === index ? { ...c, name: e.target.value } : c) } })} />
        </div>
        <div className='group'>
          <label>Allow toggling</label>
          <p className='small smaller dark-grey'>If checked, reps will be able to toggle this column on and off</p>
          <input type='checkbox' checked={column.allowToggle} onChange={e => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.map((c, i) => i === index ? { ...c, allowToggle: e.target.checked } : c) } })} />
        </div>
        <div className='group'>
          <label>Allow filtering</label>
          <p className='small smaller dark-grey'>If checked, reps will be able to filter this column</p>
          <input type='checkbox' checked={column.allowFilter} onChange={e => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.map((c, i) => i === index ? { ...c, allowFilter: e.target.checked } : c) } })} />
        </div>
        <div className='group'>
          <label>Colour code</label>
          <p className='small smaller dark-grey'>If checked, this column will be red or green depending on its status</p>
          <input type='checkbox' checked={column.colourCode} onChange={e => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.map((c, i) => i === index ? { ...c, colourCode: e.target.checked } : c) } })} />
        </div>
        <div className='group'>
          <label>Push to Movement custom field (slug)</label>
          <p className='small smaller dark-grey'>This is the slug for the custom field that will be toggled to 'y' or 'n'</p>
          <input type='text' value={column.slug} onChange={e => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.map((c, i) => i === index ? { ...c, slug: e.target.value } : c) } })} />
        </div>
        <ColumnConfig column={column} dashboard={dashboard} index={index} />

        <button className='button link naked' onClick={() => act.local.upsert({ dashboard: { ...dashboard, schema: dashboard.schema.filter((c, i) => i !== index) } })}>Delete</button>
      </div>) }
      <button
        className='button primary margin-bottom'
        onClick={() => add()}
      >Add a column</button>

      <div className='buttons margin-top double'>
        <button className='button primary' onClick={save}>Save</button>
        <Link to={`/rep_dashboards`} className="button link naked">Cancel</Link>
      </div>
    </div>
  );
};
